<template>
  <div class="profile-view">
    <header class="app-arrow-header text-center no-bg text-white">
     <!-- <a href="#" class="app-arrow-header-link left" @click="back">
        <i class="fas fa-chevron-left"></i>
      </a> -->
      <h4>Witaj w Bookme</h4>
      <h5>Tak może wyglądać Twój profil</h5>
    </header>
    <section class="profile-view-content">
      <div class="container">
        <div class="profile-view-motto">Kto czyta książki, żyje podwójnie</div>
        <div class="profile-view-name">Katarzyna Dąbrowska</div>
        <div class="profile-view-location">Łódź</div>
        <div class="profile-view-stats border-bottom">
          <ul>
            <li>23<br />książek</li>
            <li class="text-center">
              <div class="d-inline-block text-left">2<br />recenzji</div>
            </li>
            <li class="text-right">
              <div class="d-inline-block text-left">5<br />czyta</div>
            </li>
          </ul>
        </div>
          <div class="profile-view-footer mt-5">
          <router-link to="/konto/dodaj-zdjecie">
            <a href="#" class="btn-white-full w-100">Stwórz swój profil</a>
          </router-link>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Onboarding',
  created () {
    this.deleteOnboardingFinished()
  },
  methods: {
    back () {
      this.deleteToken()
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
  .profile-view {
    min-height: 100vh;
  }
</style>
